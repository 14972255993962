import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import HBEbe from '../../../assets/images/HBEbe.png'
import PMSsystem from '../../../assets/images/PMSsystem.png'
import HBEanalitika from '../../../assets/images/HBEanalitika.png'
import PMSsustav from '../../../assets/images/PMSsustav.png'
import Kokpit from '../../../assets/images/MainDashboard.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import BookingAndReservations from '../../../assets/images/b&r.png'
import ReportingDashboard from '../../../assets/images/reportingDashboard.png'
import Biling from '../../../assets/images/biling.png'
import Integrations from '../../../assets/images/integrationsAuthority.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Benefits of Utilizing a Hotel Booking Engine'
  },
  {
    id: 2,
    title: 'Key Features of a Hotel Booking Engine'
  },
  {
    id: 3,
    title: 'Importance of Integrated Hotel Booking Engine and PMS'
  },
  {
    id: 4,
    title: 'What is Rentlio Hotel Booking Engine?'
  }
]

const Content = ({ locale }) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://app.rentl.io/en/sign-up'
  }

  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>In-page topics </TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <P>
          A hotel booking engine is an online solution that allows users to make reservations for hotel rooms,
          apartments, or other accommodations through a hotel's website. It is a digital platform that allows guests to
          search for available rooms, select dates and room types, view rates, and ultimately book their stay directly
          through the hotel's website.
        </P>
        <P>
          The use of a <a href="https://rentl.io/en/booking-engine">hotel booking engine</a> has become very popular in
          recent years, as it allows travelers to easily compare prices and offerings across different hotels and book
          their stay with just a few clicks. For hotels, a booking engine can help increase direct bookings, reduce
          commission fees associated with third-party booking channels such as booking.com, and streamline the booking
          process for guests.
        </P>
        <FullWidthImg>
          <img src={HBEbe} />
        </FullWidthImg>
        <ImgTitle>Rentlio Booking Engine</ImgTitle>
        <H2 id="1" className="sections">
          Benefits of Utilizing a Hotel Booking Engine
        </H2>
        <P>
          Utilizing a Hotel Booking Engine, particularly when integrated with Rentlio's advanced Property Management
          System (PMS), provides hotels with many key benefits. One significant advantage is the increased direct
          bookings facilitated by the seamless connection between Rentlio's PMS and the Booking Engine. This integration
          ensures that real-time room availability, pricing, and other relevant information are consistently updated on
          the hotel's website, offering potential guests a user-friendly and trustworthy platform to make reservations
          directly.
        </P>
        <P>
          The direct booking channel not only reduces dependency on third-party online travel agencies (OTAs) but also
          allows hotels to establish a more personalized relationship with their guests. This enables hotels to
          implement targeted marketing strategies, loyalty programs, and customized offerings, fostering guest
          satisfaction and loyalty.
        </P>
        <P>
          Moreover, integrating Rentlio's PMS with the Booking Engine streamlines the reservation process, enhancing
          overall operational efficiency. Hotel staff can manage bookings, update room inventory, and adjust pricing
          strategies from a centralized platform, eliminating errors and ensuring that the information is consistently
          accurate across all channels.
        </P>
        <P>
          The Booking Engine also serves as a valuable tool for promoting upsells and add-ons during the reservation
          process. Hotels can showcase room upgrades, special packages, or additional services, thereby increasing the
          potential for upselling and maximizing revenue per booking.
        </P>
        <P>
          Additionally, the integration between Rentlio's PMS and the Booking Engine provides hotels with comprehensive
          reporting and analytics. By consolidating data from direct bookings and other channels, hoteliers can gain
          valuable insights into guest behavior, preferences, and overall business performance. This data-driven
          approach enables informed decision-making, allowing hotels to adapt their strategies for optimal results.
        </P>
        <GreyBlock id="2" className="sections">
          <H2>Key Features of a Hotel Booking Engine</H2>
          <H3>Real-time availability:</H3>
          <P>
            One of the key features of a hotel booking engine is its ability to provide real-time availability of rooms.
            This means that guests can see the current availability of rooms for the dates they are looking to book, and
            can book their rooms immediately.
          </P>
          <H3>Easy search and booking process:</H3>
          <P>
            A hotel booking engine should have a simple and user-friendly interface that allows guests to search for
            available rooms easily, select their preferred room type and dates, view rates, and book their stay in just
            a few clicks.{' '}
          </P>
          <H3>Multiple payment options:</H3>
          <P>
            A good hotel booking engine should offer multiple payment options to guests, including credit cards, debit
            cards, and online payment gateways. This allows guests to choose the payment method that is most convenient
            for them.
          </P>
          <H3>Mobile-friendly design:</H3>
          <P>
            With more and more people using their mobile devices to book hotel rooms, a hotel booking engine should have
            a mobile-friendly design optimized for smartphones and tablets.
          </P>
          <H3>Personalization:</H3>
          <P>
            A hotel booking engine can be personalized to provide guests with a customized booking experience. For
            example, it can offer recommendations based on the guest's previous booking history or personalized
            promotions based on their preferences.
          </P>
          <H3>Integration with social media:</H3>
          <P>
            A hotel booking engine can be integrated with social media platforms to allow guests to share their bookings
            with their friends and followers and to provide social proof of the hotel's popularity and reputation.
          </P>
          {/* <PMSsustavIMG>
            <img src={PMSsustav}></img>
          </PMSsustavIMG> */}
        </GreyBlock>
        <Form locale="en" />
        <H2 id="3" className="sections">
          Importance of Integrated Hotel Booking Engine and PMS
        </H2>
        <P>
          In the hospitality industry, a{' '}
          <a href="https://rentl.io/en/property-management-system">Property Management System (PMS)</a> and a hotel
          booking engine (HBE) are two of the most important technologies hotels use to manage their operations and
          increase revenue. These two systems are essential for managing reservations, room inventory, and guest
          information. But why is it important to integrate these two systems? Let's take a look.
        </P>
        <FullWidthImg>
          <img src={PMSsystem} />
        </FullWidthImg>
        <H3>What is a Property Management System (PMS)?</H3>
        <P>
          A <a href="https://rentl.io/en/hotel-property-management-system">Property Management System (PMS)</a> is a
          software application hotels use to manage their day-to-day operations. This includes everything from managing
          reservations and room inventory to tracking guest information and processing payments. A PMS is a central hub
          for all hotel operations, allowing staff to manage all aspects of the guest experience from a single platform.
        </P>
        <P>
          Integrating a PMS and hotel booking engine is crucial for hotels that want to streamline operations and
          increase revenue. Below are some of the key reasons why:
        </P>
        <H3>Improved efficiency</H3>
        <P>
          When a hotel's PMS and booking engine are integrated, staff can manage all aspects of the guest experience
          from a single platform. This means they don't have to switch between multiple systems to manage reservations,
          room inventory, and guest information. All this information in one place allows staff to work more efficiently
          and respond to guest requests more quickly.
        </P>
        <H3>Increased revenue</H3>
        <P>
          By integrating a PMS and HBE, hotels can increase their revenue by allowing guests to book directly through
          their website or mobile application. This not only reduces commission fees associated with third-party booking
          channels, but it also provides hotels with an opportunity to upsell guests on additional services, such as
          room upgrades or spa treatments.
        </P>
        <H3>Better guest experience</H3>
        <P>
          Integrating a PMS and hotel booking engine can also improve the guest experience by providing guests with a
          seamless booking process. Guests can easily search for available rooms, select dates and room types, view
          rates, and ultimately book their stay directly through the hotel's website or mobile application. This
          provides a more personalized and convenient experience for guests, which can lead to increased loyalty and
          repeat business.
        </P>
        <H3>Enhanced reporting and analytics</H3>
        <P>
          Integrating a property management system and booking engine can also provide hotels with enhanced reporting
          and analytics capabilities. By having all of their data in one place, hotels can easily track their revenue,
          occupancy rates, and other key performance indicators. This can help hotels make data-driven decisions about
          pricing, marketing, and other aspects of their business.
        </P>
        <GreyBlock>
          <H2>Why a booking engine is important for your hotel?</H2>
          <P>
            Implementing a booking engine for your hotel is crucial. It allows guests to conveniently and securely
            reserve rooms online, aligning with modern travelers' expectations. The significance of a booking engine
            stems from several key factors.
          </P>
          <P>
            Firstly, it empowers guests to self-serve by providing real-time information on room availability and
            pricing without direct communication. This convenience is highly valued by contemporary travelers who
            appreciate the ability to make informed decisions at their own pace.
          </P>
          <P>
            Furthermore, online bookings through a secure booking engine instill trust among guests. The fact that the
            booking engine can securely process credit card payments and store guest data in a centralized, protected
            environment adds an extra layer of confidence for both guests and hoteliers.
          </P>
          <P>
            The efficiency of online booking is another compelling reason. With a booking engine, guests can swiftly
            confirm reservations without engaging in prolonged exchanges via phone or email. This eliminates the
            time-consuming back-and-forth, providing a quicker and more streamlined booking experience.
          </P>
          <P>
            Direct booking perks are also a notable advantage. Many guests seek a hotel's website to secure additional
            benefits when booking directly. This could include special offers, discounts, or other incentives, allowing
            hotels to save on third-party commissions and enhance guest satisfaction.
          </P>
          <P>
            An online booking engine is considered standard in the modern hospitality industry to maintain a
            professional image. A lack of this feature might convey disorganization and potentially deter potential
            guests from choosing your property over others.
          </P>
          <P>
            Moreover, owning the guest relationship becomes possible with direct bookings. By securing reservations
            through your website, hotels can personalize services, cultivate guest loyalty, and enhance overall
            profitability. It's an opportunity to provide a more tailored experience, encouraging guests to return and
            become repeat customers.
          </P>
          <P>
            A booking engine is not merely a technological tool but a strategic asset that aligns with guest
            expectations, instills trust, enhances efficiency, offers direct booking perks, maintains professionalism,
            and enables hotels to cultivate lasting guest relationships.
          </P>
        </GreyBlock>
        <H2 id="4" className="sections">
          What is Rentlio Hotel Booking Engine?
        </H2>
        <P>
          Rentlio Booking Engine is a cloud-based solution. It provides a fully customizable and commission-free online
          booking system for hotels, resorts, and vacation rentals. The booking engine enables hoteliers to manage
          reservations, room inventory, rates, and availability in real-time while providing guests with a seamless
          booking experience.
        </P>
        <P>
          The Rentlio Booking Engine allows hoteliers to manage their bookings and inventory easily from a single
          platform. It also supports multiple languages and currencies, making it easy for hotels to cater to guests
          worldwide. Additionally, the booking engine is mobile-responsive, meaning guests can easily book their stays
          on any device, including desktops, tablets, and smartphones.
        </P>
        <P>
          One of the key features of the Rentlio Booking Engine is its flexibility and customization options. Hoteliers
          can customize their booking engine to match their brand, with options to add custom logos, colors, and other
          design elements. The booking engine also supports various room types, pricing models, and promotions, allowing
          hoteliers to create a unique and personalized booking experience for their guests.
        </P>
        <P>
          The Rentlio Booking Engine also provides a range of analytics and reporting features, enabling hoteliers to
          track their performance and make data-driven decisions. With real-time reporting on bookings, revenue,
          occupancy rates, and other key metrics, hoteliers can easily identify trends and optimize their pricing and
          marketing strategies.
        </P>
        <P>
          Overall, the Rentlio Booking Engine is a comprehensive solution for hoteliers looking to streamline their
          booking processes and increase revenue. It provides a customizable booking system that is easy to use for both
          hoteliers and guests, while also offering advanced features and analytics to help hotels stay competitive and
          grow their business.
        </P>

        <ButtonCTA onClick={(e) => handleClick(e)}>Get Free Consultations</ButtonCTA>
        {/* <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText> */}
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
