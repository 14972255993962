import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/RBEbanner.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/en/booking-engine'
  }
  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <Title>What is a Hotel Booking Engine?</Title>
          <Text>
            Discover how you can manage direct booking and generate more revenue by eliminating expensive OTA channels.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Find out more</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
